import React from "react";
import { Switch, Select } from "antd";
import { Form } from "antd-mobile";
import { useDispatch } from "react-redux";
import { updateSetting } from "@/store/modules/setting";
import { ShuttleBox } from "@/components/ShuttleBox";
import { homelandItems } from "../constants";

const HomelandTab = ({ setting }) => {
    const { homeland: config } = setting; // 从 Redux 中提取 Homeland 设置
    const category = "homeland";
    const dispatch = useDispatch();

    const handleChange = (key, value) => {
        dispatch(updateSetting({ category, key, value }));
    };

    // 创建 0-23 的小时选项
    const hourOptions = Array.from({ length: 24 }, (_, index) => ({
        value: index,
        label: `${index} 点`,
    }));

    return (
        <Form layout="horizontal">
            <h4>福地设置</h4>

            {/* 开启功能 */}
            <Form.Item label="开启功能" valuePropName="checked">
                <Switch checked={config.enabled ?? false} onChange={(checked) => handleChange("enabled", checked)} />
            </Form.Item>

            {/* 高效偷取 */}
            <Form.Item label="高效偷取" help="同时拉取最后时刻撤回" valuePropName="checked">
                <Switch checked={config.flashSteal ?? false} onChange={(checked) => handleChange("flashSteal", checked)} />
            </Form.Item>

            {/* 自动收获 */}
            <Form.Item label="自动收获" help="开启后自动收取物品" valuePropName="checked">
                <Switch checked={config.autoHarvest ?? false} onChange={(checked) => handleChange("autoHarvest", checked)} />
            </Form.Item>

            {/* 普通规则 ShuttleBox */}
            <Form.Header>偷桃规则</Form.Header>
            <ShuttleBox
                availableItems={homelandItems.filter(
                    (item) => !(config.rules || []).includes(item.id) // 确保 rules 存在
                )}
                selectedItems={(config.rules || []).map(
                    (id) => homelandItems.find((item) => item.id === id) // 确保 rules 存在
                )}
                helpText={{ selected: "采集优先级按照从上到下排列" }}
                onChange={(val) => handleChange("rules", val)}
            />

            {/* 采集时间 */}
            <Form.Item label="采集时间" help="请选择采集的时间段(0-23点) 建议只选9个小时">
                <Select
                    mode="multiple"
                    value={config.collectionHours ?? [10, 11, 12, 18, 19, 20, 21, 22, 23]}
                    onChange={(value) => {
                        handleChange("collectionHours", value);
                    }}
                    style={{ width: "100%" }}
                    maxTagCount={23}
                    placeholder="请选择时间"
                >
                    {hourOptions.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Form>
    );
};

export default HomelandTab;
