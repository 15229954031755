import React from "react";
import { Form } from "antd-mobile";
import { InputNumber, Select, Switch } from "antd";
import { attributeDict, qualityDict, anyOption } from "../constants";

export const ChopTreeSetting = ({ hasSeparation, setting, onChange }) => {
    const handleAttributeChange = (index, type, value, isMain = false) => {
        const isAnyOption = value.includes(anyOption);

        const updatedValue = isAnyOption ? [11, 12, 13, 14, 15, 16] : value.map((val) => parseInt(val));

        if (isMain) {
            const updatedMain = { ...setting.main, [type]: updatedValue };
            onChange("main", updatedMain);
        } else {
            const updatedConditions = [...setting.separation];
            updatedConditions[index] = {
                ...updatedConditions[index],
                [type]: updatedValue,
            };
            onChange("separation", updatedConditions);
        }
    };

    const getOptions = (dict) =>
        Object.keys(dict).map((key) => ({
            label: dict[key],
            value: parseInt(key),
        }));

    const getAttributeOptions = (type) =>
        type === "secondaryAttribute"
            ? [
                  { label: anyOption, value: anyOption },
                  ...Object.keys(attributeDict)
                      .slice(6)
                      .map((key) => ({
                          label: attributeDict[key],
                          value: parseInt(key),
                      })),
              ]
            : Object.keys(attributeDict)
                  .slice(0, 6)
                  .map((key) => ({
                      label: attributeDict[key],
                      value: parseInt(key),
                  }));

    const renderAttributeSelection = (label, index, type, isMain = false) => {
        const source = isMain ? setting.main : setting.separation[index];
        const current = source[type] || [];

        return (
            <Form.Item label={label}>
                <Select
                    mode="multiple"
                    value={current}
                    options={getAttributeOptions(type)}
                    onChange={(value) => handleAttributeChange(index, type, value, isMain)}
                    style={{ width: "100%" }}
                    placeholder="请选择"
                />
            </Form.Item>
        );
    };

    return (
        <Form layout="horizontal">
            <Form.Item label="显示结果">
                <Switch checked={setting.showResult} onChange={(val) => onChange("showResult", val)} />
            </Form.Item>

            {/* 停止条件 */}
            <Form.Header>停止条件</Form.Header>
            <Form.Item label="桃子数量" help="桃子数量 < 该数 砍树停止">
                <InputNumber min={1} value={setting.stop.stopNum} style={{ width: "100%" }} onChange={(e) => onChange("stop", { ...setting.stop, stopNum: e })} />
            </Form.Item>

            {/* 砍几颗桃 */}
            <Form.Item label="砍几颗桃" help="-1为无穷大 意思是会一直砍">
                <InputNumber
                    min={-1}
                    value={setting.stop.doNum}
                    style={{ width: "100%" }}
                    formatter={(value) => (String(value) === "-1" ? "无穷大" : value)}
                    parser={(value) => (value === "无穷大" ? -1 : parseInt(value || 0, 10))}
                    onChange={(value) => onChange("stop", { ...setting.stop, doNum: value })}
                />
            </Form.Item>

            {/* 玩家等级 */}
            <Form.Item label="玩家等级" help="等级等于该数时停止 -1为无穷大 意思是会一直砍">
                <InputNumber
                    min={-1}
                    value={setting.stop.level}
                    style={{ width: "100%" }}
                    formatter={(value) => (String(value) === "-1" ? "无穷大" : value)}
                    parser={(value) => (value === "无穷大" ? -1 : parseInt(value || 0, 10))}
                    onChange={(value) => onChange("stop", { ...setting.stop, level: value })}
                />
            </Form.Item>

            <Form.Header>基础设置</Form.Header>
            {/* 最低品质 */}
            <Form.Item label="最低品质" help="最低能接受的品质">
                <Select value={setting.quality} onChange={(value) => onChange("quality", value)} style={{ width: "100%" }} options={getOptions(qualityDict)} />
            </Form.Item>

            {/* 等级偏移 */}
            <Form.Item label="等级偏移" help="新装备等级高于现有装备时几级时降低要求">
                <InputNumber min={1} value={setting.levelOffset} style={{ width: "100%" }} onChange={(e) => onChange("levelOffset", e)} />
            </Form.Item>

            {/* 属性选择 */}
            {hasSeparation ? (
                ["元体", "阳神", "阴身"].map((name, index) => (
                    <>
                        <Form.Header>{name}</Form.Header>
                        {renderAttributeSelection("主属性", index, "primaryAttribute")}
                        {renderAttributeSelection("副属性", index, "secondaryAttribute")}
                    </>
                ))
            ) : (
                <>
                    <Form.Header>属性设置</Form.Header>
                    {renderAttributeSelection("主属性", 0, "primaryAttribute", true)}
                    {renderAttributeSelection("副属性", 0, "secondaryAttribute", true)}
                </>
            )}
        </Form>
    );
};
